<template>
  <v-row class="my-1" align="center" no-gutters>
    <v-col cols="1"
      ><v-avatar size="30"><v-img :src="avatar_img"></v-img></v-avatar
    ></v-col>
    <v-col cols="6">
      {{ nome[0] }}
    </v-col>
    <v-col cols="2">
      {{ item.qta }}
    </v-col>
    <v-col cols="1">
      <v-btn icon @click="remove()" color="red"
        ><v-icon>mdi-delete</v-icon></v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  data() {
    return {};
  },
  computed: {
    prodotto() {
      return this.$store.getters.get_prodotto_by_id(this.item.id);
    },
    nome() {
      return this.prodotto.nome.split(" in ");
    },
    avatar_img() {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.prodotto.id;
    },
  },
  methods: {
    remove() {
      this.$emit("remove", this.item.id);
    },
  },
};
</script>

