<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-row
        align="center"
        class="py-3"
        no-gutters
        :class="hover ? 'green lighten-5' : ''"
      >
        <v-col cols="3">
          <v-avatar class="mx-2" size="30"
            ><v-img :src="avatar_fornitore"></v-img
          ></v-avatar>
          {{ fornitore.nome }}
        </v-col>
        <v-col cols="1">{{ lotto.codice }}</v-col>
        <v-col cols="2">{{ data }}</v-col>
        <v-col cols="1">{{ settimana }}</v-col>
        <v-col cols="1">{{ anno }}</v-col>
        <v-col cols="2">{{ lotto.n_documento }}</v-col>

        <v-col cols="1">
          <v-dialog v-model="dettaglio_colture" width="1400">
            <template v-slot:activator="{ on }">
              <v-btn color="blue" class="mr-2" icon v-on="on"
                ><v-icon>mdi-open-in-new</v-icon></v-btn
              >
            </template>
            <v-card>
              <v-card-title class="grey--text text-subtitle-1"
                ><span class="mr-2">Colture Associate Lotto: </span>  <span class="purple--text">{{lotto.codice}}</span>

                <v-spacer></v-spacer>
                <v-snackbar v-model="exist_alert" top shaped color="green">
                  <v-icon class="mr-2">mdi-information</v-icon>
                  Il prodotto esiste già
                </v-snackbar>
                <v-dialog v-model="dialog_nuova_coltura" width="800">
                  <template v-slot:activator="{ on }">
                    <v-btn color="success" outlined v-on="on"
                      >Aggiungi Coltura</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title class="grey--text text-subtitle-1">
                      Nuova Coltura
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <v-autocomplete
                            v-model="nuovo_prodotto"
                            placeholder="Prodotto"
                            :items="prodotti"
                            outlined
                            item-text="nome"
                            item-value="id"
                            hide-details
                          >
                            <template v-slot:selection="data">
                              <v-list-item-avatar>
                                <v-img :src="avatar_img(data.item.id)" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ nome_split(data.item.nome)[0] }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ nome_split(data.item.nome)[1] }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                            <template v-slot:item="data">
                              <v-list-item-avatar>
                                <img :src="avatar_img(data.item.id)" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ nome_split(data.item.nome)[0] }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ nome_split(data.item.nome)[1] }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-dialog
                            ref="dialog"
                            v-model="dialog_data"
                            :return-value.sync="nuovo_prodotto_data"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="nuovo_prodotto_data"
                                label="Data di Trapianto"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="nuovo_prodotto_data"
                              scrollable
                              locale="IT"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="dialog_data = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(nuovo_prodotto_data)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select
                            outlined
                            label="Zona"
                            :items="zone"
                            item-text="nome"
                            item-value="id"
                            :loading="loading_sposta"
                            v-model="zone_destinazione"
                            multiple
                            chips
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        color="green"
                        :disabled="
                          !nuovo_prodotto || zone_destinazione.length == 0
                        "
                        :loading="loading_nuova"
                        @click="aggiungi_nuovo()"
                        >Aggiungi</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="3"> Coltura </v-col>
                  <v-col cols="2"> Zona </v-col>
                  <v-col cols="2"> Data Trapianto </v-col>
                  <v-col cols="1"> Data Posiziz. </v-col>
                  <v-col cols="2" class="d-flex justify-center">
                    In produzione
                  </v-col>
                  <v-col cols="2" class="d-flex justify-center">
                    Spostamenti
                  </v-col>
                </v-row>
                <v-divider class="my-2" />
                <ColturaAssociataEl
                  v-for="coltura in lotto.colture_associate"
                  :key="coltura.id"
                  :id_lotto="lotto.id"
                  :coltura="coltura"
                ></ColturaAssociataEl>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text color="grey" @click="dettaglio_colture = false"
                  >Chiudi</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog_elimina" width="400">
            <template v-slot:activator="{ on }">
              <v-btn icon small color="red" v-on="on" v-show="hover">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="red--text"> Elimina Lotto </v-card-title>
              <v-card-text> Sei Sicuro? </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  outlined
                  color="red"
                  @click="elimina_lotto()"
                  :loading="loading_elimina"
                  >Elimina</v-btn
                >
                <v-btn text color="grey" @click="dialog_elimina = false"
                  >annulla</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-hover>
    <v-divider></v-divider>
  </div>
</template>
<script>
import moment from "moment";
import ColturaAssociataEl from "./coltura_associata_element.vue";
export default {
  components: {
    ColturaAssociataEl,
  },
  props: {
    lotto: Object,
  },
  data() {
    return {
      dialog_elimina: false,
      loading_elimina: false,
      dettaglio_colture: false,
      nuovo_prodotto: null,
      dialog_data: false,
      dialog_nuova_coltura: false,
      nuovo_prodotto_data: moment().format("YYYY-MM-DD"),
      zone_destinazione: [],
      loading_nuova: false,
      loading_sposta: false,
      exist_alert: false,
    };
  },
  computed: {
    fornitore() {
      return this.$store.getters.get_fornitore_by_id(this.lotto.id_fornitore);
    },
    avatar_fornitore() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/fornitore/" +
        this.fornitore.id +
        "/avatar" +
        "?t=" +
        this.fornitore.logo_md5
      );
    },
    settimana() {
      return moment.unix(this.lotto.ts_documento).format("WW");
    },
    anno() {
      return moment.unix(this.lotto.ts_documento).format("YYYY");
    },
    data() {
      return moment.unix(this.lotto.ts_documento).format("dddd DD MMMM");
    },
    prodotti() {
      return this.$store.getters.get_lista_prodotti_raw;
    },
    zone() {
      return this.$store.getters.get_zone_coltivazione;
    },
  },
  methods: {
    elimina_lotto() {
      this.loading_elimina = true;
      this.$store.dispatch("delete_lotto", this.lotto.id).finally(() => {
        this.loading_elimina = false;
      });
    },
    avatar_img(id) {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + id;
    },
    nome_split(nome) {
      return nome.split(" in ");
    },
    aggiungi_nuovo() {
      this.loading_nuova = true;
      this.$store
        .dispatch("aggiungi_coltura_lotto", {
          id_lotto: this.lotto.id,
          id_coltura: this.nuovo_prodotto,
          ts_trapianto: moment(this.nuovo_prodotto_data).format("X"),
          zone: this.zone_destinazione,
        })
        .then((res) => {
          if (res == "exist") {
            console.log("Già esistente");
            this.exist_alert = true;
          }
          this.dialog_nuova_coltura = false;
          this.loading_nuova = false;
        });
    },
  },
};
</script>