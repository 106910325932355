<template>
  <v-row align="center">
    <v-col cols="3">
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="avatar_img" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ nome[0] }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ nome[1] }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="2">
      <v-row v-for="zona in coltura.zona_attuale" :key="zona">
        {{ get_zona(zona).nome }}
      </v-row>
      <!-- {{ zona.nome }} -->
    </v-col>
    <v-col cols="2">
      {{ data_trapianto }}
    </v-col>
    <v-col cols="1">
      {{ data_posizionamento }}
    </v-col>
    <v-col cols="2" class="d-flex justify-center">
      <v-btn icon @click="modifica_stato()">
        <v-icon v-if="coltura.esaurito" color="red">mdi-close</v-icon>
        <v-icon v-else color="green">mdi-check</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="2" class="d-flex justify-center">
      <v-dialog v-model="dialog_spostamenti" width="400">
        <template v-slot:activator="{ on }">
          <v-btn icon small color="blue" v-on="on"
            ><v-icon>mdi-swap-horizontal</v-icon></v-btn
          >
        </template>
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-row
              v-for="(zona, index) in coltura.zone_precendenti"
              :key="zona + index"
            >
              {{ get_zona(zona).nome }}
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-menu
        v-model="menu_spostamento"
        min-width="400"
        left
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-btn small icon color="purple" v-on="on"
            ><v-icon>mdi-debug-step-over</v-icon></v-btn
          >
        </template>
        <v-card class="pa-4">
          <v-row>
            <v-col cols="8">
              <v-select
                outlined
                label="Sposta in ..."
                :items="zone"
                item-text="nome"
                item-value="id"
                :loading="loading_sposta"
                v-model="zone_destinazione"
                multiple
                chips
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-dialog
                ref="dialog"
                v-model="dialog_data"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Data di Spostamento"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable locale="IT">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dialog_data = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              color="green"
              :disabled="!zone_destinazione"
              :loading="loading_sposta"
              @click="conferma()"
              >Conferma</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-menu v-model="menu_delete">
        <template v-slot:activator="{ on }">
          <v-btn small color="red" icon v-on="on"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
        <v-card>
          <v-card-title>Sei Sicuro ?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="menu_delete = false"
              >annulla</v-btn
            >
            <v-btn color="red" outlined @click="elimina_coltura()"
              >Elimina</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  props: {
    id_lotto: String,
    coltura: Object,
  },
  data() {
    return {
      dialog_spostamenti: false,
      menu_spostamento: false,
      loading_sposta: false,
      zone_destinazione: null,
      dialog_data: false,
      date: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    prodotto() {
      return this.$store.getters.get_prodotto_by_id(this.coltura.id_prodotto);
    },
    nome() {
      return this.prodotto.nome.split(" in ");
    },
    avatar_img() {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.prodotto.id;
    },
    data_trapianto() {
      return moment.unix(this.coltura.ts_trapianto).format("DD/MM/YYYY");
    },
    data_posizionamento() {
      return moment.unix(this.coltura.ts_posizionamento).format("DD/MM/YYYY");
    },
    zone() {
      return this.$store.getters.get_zone_coltivazione;
    },
  },
  methods: {
    get_zona(id) {
      return this.$store.getters.get_zona_by_id(id);
    },
    conferma() {
      this.loading_sposta = true;
      this.$store
        .dispatch("sposta_lotto_zona_coltura", {
          id_lotto: this.id_lotto,
          id_coltura: this.coltura.id,
          zone_destinazione: this.zone_destinazione,
          ts_posizionamento: moment(this.date).format("X"),
        })
        .then(() => {
          this.loading_sposta = false;
          this.menu_spostamento = false;
        });
    },
    modifica_stato() {
      this.$store.dispatch("cambia_stato_produzione_coltura", {
        id_lotto: this.id_lotto,
        id_coltura: this.coltura.id,
        esaurito: !this.coltura.esaurito,
      });
    },
    elimina_coltura() {
      this.$store.dispatch("elimina_coltura_lotto", {
        id_lotto: this.id_lotto,
        id_coltura: this.coltura.id,
      });
    },
  },
};
</script>