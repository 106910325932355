<template>
  <v-dialog width="400" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn outlined color="green" v-on="on">
        <v-icon class="mr-2">mdi-plus</v-icon>
        Nuovo Lotto
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Nuovo Lotto
      </v-card-title>
      <v-card-text>
        <v-row justify="center" class="mt-4">
          <v-form v-model="valid" ref="form">
            <v-col cols="12">
              <v-autocomplete
                outlined
                prepend-inner-icon="mdi-account"
                label="Fornitore"
                :items="fornitori"
                item-text="nome"
                item-value="id"
                v-model="id_fornitore"
                :rules="rule"
              >
                <template v-slot:item="data">
                  <!-- <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template> -->
                  <template>
                    <v-list-item-avatar>
                      <v-img :src="avatar_fornitore(data.item.id)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.nome"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-menu
                v-model="menu_data"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatted_date"
                    label="Data Documento"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu_data = false"
                  locale="it-it"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                disabled
                prepend-inner-icon="mdi-calendar"
                label="Settimana"
                v-model="settimana"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                prepend-inner-icon="mdi-file-outline"
                label="N. Documento"
                v-model="n_documento"
                :rules="rule"
              ></v-text-field>
            </v-col>
          </v-form>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="dialog = false">Annulla</v-btn>
        <v-btn outlined color="green" :loading="loading" @click="invia_lotto()"
          ><v-icon class="mr-2">mdi-send</v-icon> Invia</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      rule: [(v) => !!v || "Non può essere vuoto"],
      id_fornitore: null,
      menu_data: false,
      n_documento: "",
      date: moment().format("YYYY-MM-DD"),
      loading: false,
    };
  },
  computed: {
    fornitori() {
      return this.$store.getters.get_fornitori;
    },
    formatted_date() {
      moment.locale("it_IT");
      return moment(this.date).format("dddd DD MMMM");
    },
    date_ts() {
      return moment(this.date).format("X");
    },
    settimana() {
      return moment(this.date).format("WW");
    },
  },
  methods: {
    avatar_fornitore(id_fornitore) {
      return (
        process.env.VUE_APP_API_ROOT + "/fornitore/" + id_fornitore + "/avatar"
      );
    },
    invia_lotto() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      let codice =
        this.$store.getters.get_fornitore_by_id(this.id_fornitore).codice +
        moment(this.date).format("WWYY");
      console.log(codice);
      this.$store
        .dispatch("invia_lotto", {
          id_fornitore: this.id_fornitore,
          ts_documento: this.date_ts,
          codice: codice,
          n_documento: this.n_documento,
        })
        .then(() => {
          this.id_fornitore = null;
          this.n_documento = null;
          this.dialog = false;
        })
        .catch((err) => {
          if (err.response.status == 409) {
            alert("Codice già esistente");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>