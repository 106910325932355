var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.avatar_img}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.nome[0])+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.nome[1])+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},_vm._l((_vm.coltura.zona_attuale),function(zona){return _c('v-row',{key:zona},[_vm._v(" "+_vm._s(_vm.get_zona(zona).nome)+" ")])}),1),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.data_trapianto)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.data_posizionamento)+" ")]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.modifica_stato()}}},[(_vm.coltura.esaurito)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")]):_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")])],1)],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"2"}},[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"blue"}},on),[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1)]}}]),model:{value:(_vm.dialog_spostamenti),callback:function ($$v) {_vm.dialog_spostamenti=$$v},expression:"dialog_spostamenti"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',_vm._l((_vm.coltura.zone_precendenti),function(zona,index){return _c('v-row',{key:zona + index},[_vm._v(" "+_vm._s(_vm.get_zona(zona).nome)+" ")])}),1)],1)],1),_c('v-menu',{attrs:{"min-width":"400","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"purple"}},on),[_c('v-icon',[_vm._v("mdi-debug-step-over")])],1)]}}]),model:{value:(_vm.menu_spostamento),callback:function ($$v) {_vm.menu_spostamento=$$v},expression:"menu_spostamento"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"outlined":"","label":"Sposta in ...","items":_vm.zone,"item-text":"nome","item-value":"id","loading":_vm.loading_sposta,"multiple":"","chips":""},model:{value:(_vm.zone_destinazione),callback:function ($$v) {_vm.zone_destinazione=$$v},expression:"zone_destinazione"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data di Spostamento","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialog_data),callback:function ($$v) {_vm.dialog_data=$$v},expression:"dialog_data"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"IT"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog_data = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"green","disabled":!_vm.zone_destinazione,"loading":_vm.loading_sposta},on:{"click":function($event){return _vm.conferma()}}},[_vm._v("Conferma")])],1)],1)],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"red","icon":""}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}]),model:{value:(_vm.menu_delete),callback:function ($$v) {_vm.menu_delete=$$v},expression:"menu_delete"}},[_c('v-card',[_c('v-card-title',[_vm._v("Sei Sicuro ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.menu_delete = false}}},[_vm._v("annulla")]),_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.elimina_coltura()}}},[_vm._v("Elimina")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }