<template>
  <v-card height="400">
    <v-card-title class="grey--text text-subtitle-1">
      Torbe
      <v-spacer></v-spacer>
      <NuovaTorba />
    </v-card-title>
    <v-card-subtitle mb-n4>
      <v-divider class="my-4" />
      <v-row no-gutters>
        <v-col cols="3" class="font-weight-medium pl-2"> Fornitore </v-col>
        <v-col cols="1" class="font-weight-medium"> Codice </v-col>
        <v-col cols="2" class="font-weight-medium">Nome </v-col>
      </v-row>
      <v-divider class="mt-4" />
    </v-card-subtitle>
    <v-card-text class="pb-8 scroll">
      <template v-for="torba in torbe">
        <TorbaElement :torba="torba" :key="torba.id" />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import TorbaElement from "./torba_element.vue";
import NuovaTorba from "./torba_nuovo.vue";
export default {
  components: {
    TorbaElement,
    NuovaTorba,
  },
  data() {
    return {
      anno_rif: moment().format("YYYY"),
      id_fornitore: null,
      loading: false,
    };
  },
  computed: {
    torbe() {
      return this.$store.getters.get_torbe;
    },
    fornitori() {
      return this.$store.getters.get_fornitori;
    },
  },
  methods: {
    avatar_fornitore(id_fornitore) {
      return (
        process.env.VUE_APP_API_ROOT + "/fornitore/" + id_fornitore + "/avatar"
      );
    },
    filtra_lotti() {
      this.loading = true;
      this.$store
        .dispatch("filtra_lotti", {
          inizio: moment("01-01-" + this.anno_rif)
            .startOf("year")
            .format("X"),
          fine: moment("01-01-" + this.anno_rif)
            .endOf("year")
            .format("X"),
          id_fornitore: this.id_fornitore ? this.id_fornitore : "0",
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 250px;
  overflow-y: scroll;
}

.scroll_extended {
  height: 610px;
  overflow-y: scroll;
}
</style>