<template>
  <v-card height="500">
    <v-card-title class="grey--text text-subtitle-1">
      Lotti
      <v-autocomplete
        v-model="anno_rif"
        :items="anni"
        outlined
        label="Anno Riferimento"
        dense
        rounded
        class="ml-4"
        hide-details
        @change="filtra_lotti()"
      >
      </v-autocomplete>
      <v-autocomplete
        outlined
        rounded
        hide-details
        dense
        clearable
        class="ml-4"
        prepend-inner-icon="mdi-account"
        label="Fornitore"
        :items="fornitori"
        item-text="nome"
        item-value="id"
        v-model="id_fornitore"
        @change="filtra_lotti()"
      >
        <template v-slot:item="data">
          <v-list-item-avatar>
            <v-img :src="avatar_fornitore(data.item.id)" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.nome"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-spacer></v-spacer>
      <v-btn outlined color="purple" class="mr-2" @click="open_stampa = true"
        ><v-icon class="mr-2">mdi-printer</v-icon> Stampa</v-btn
      >
      <StampaVarieta :open="open_stampa" @close="open_stampa = false" />
      <RicercaLotto />
      <NuovoLotto />
    </v-card-title>
    <v-card-subtitle class="mb-n4">
      <v-divider class="my-4" />
      <v-row no-gutters>
        <v-col cols="3" class="font-weight-medium pl-2"> Fornitore </v-col>
        <v-col cols="1" class="font-weight-medium"> Codice </v-col>
        <v-col cols="2" class="font-weight-medium">Data </v-col>
        <v-col cols="1" class="font-weight-medium"> Settimana </v-col>
        <v-col cols="1" class="font-weight-medium"> Anno</v-col>
        <v-col cols="2" class="font-weight-medium"> Documento </v-col>
      </v-row>
      <v-divider class="mt-3" />
    </v-card-subtitle>

    <v-card-text class="scroll">
      <template v-for="lotto in lotti">
        <LottoElement :lotto="lotto" :key="lotto.id" />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import LottoElement from "./lotto_elementt.vue";
import NuovoLotto from "./lotto_nuovo.vue";
import RicercaLotto from "./ricerca_lotto.vue";
import StampaVarieta from "./stampa_varieta.vue"
export default {
  components: {
    LottoElement,
    NuovoLotto,
    RicercaLotto,
    StampaVarieta
  },
  data() {
    return {
      anno_rif: moment().format("YYYY"),
      id_fornitore: null,
      loading: false,
      open_stampa: false,
    };
  },
  computed: {
    lotti() {
      return this.$store.getters.get_lotti;
    },
    anni() {
      let adesso = moment().format("YYYY");
      return [adesso, adesso - 1, adesso - 2];
    },
    fornitori() {
      return this.$store.getters.get_fornitori;
    },
  },
  methods: {
    avatar_fornitore(id_fornitore) {
      return (
        process.env.VUE_APP_API_ROOT + "/fornitore/" + id_fornitore + "/avatar"
      );
    },
    filtra_lotti() {
      this.loading = true;
      this.$store
        .dispatch("filtra_lotti", {
          inizio: moment("01-01-" + this.anno_rif)
            .startOf("year")
            .format("X"),
          fine: moment("01-01-" + this.anno_rif)
            .endOf("year")
            .format("X"),
          id_fornitore: this.id_fornitore ? this.id_fornitore : "0",
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 340px;
  overflow-y: scroll;
}

.scroll_extended {
  height: 610px;
  overflow-y: scroll;
}
</style>