<template>
  <LottiDesktop v-if="!$vuetify.breakpoint.mobile" />
    <v-row justify="center" class="mt-12" v-else>Non disponibile per mobile</v-row>

</template>

<script>
import LottiDesktop from "@/components/Lotti/desktop/tracciabilità_desktop.vue"
export default {
  components: {
    LottiDesktop
  },
};
</script>