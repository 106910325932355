<template>
  <v-dialog width="800" v-model="dialog_ricerca">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" outlined color="blue" class="mr-2"
        ><v-icon class="mr-2">mdi-magnify</v-icon>Ricerca</v-btn
      >
    </template>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1"
        >Ricerca Lotto</v-card-title
      >
      <v-card-text>
        <v-row align="center">
          <v-col cols="8">
            <v-text-field
              hide-details=""
              v-model="code"
              outlined
              label="Inserisci codice prodotto"
              append-icon="mdi-camera"
              @click:append="open_scan"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn outlined large block color="green" @click="cerca()"
              >Cerca</v-btn
            >
          </v-col>
        </v-row>
        <template v-if="movimenti">
          <v-row>
            <v-col class="text-subtitle-2"> Dettaglio Prodotto </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-list-item class="ml-n5">
                <v-list-item-avatar>
                  <v-img :src="avatar_prodotto"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ nome_prodotto[0] }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ nome_prodotto[1] }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ prodotto.nome_sc }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="text-subtitle-2"> Dettaglio Lotto </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"> Fornitore </v-col>
            <v-col cols="2"> Codice </v-col>
            <v-col cols="3"> Data </v-col>
            <v-col cols="3"> N. Documento </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-avatar class="mr-2" size="30">
                <v-img :src="avatar_fornitore" />
              </v-avatar>
              {{ fornitore.nome }}
            </v-col>
            <v-col cols="2">
              <v-icon class="mr-2">mdi-barcode</v-icon>
              {{ lotto.codice }}
            </v-col>
            <v-col cols="3">
              <v-icon class="mr-2">mdi-calendar</v-icon>

              {{ data_lotto }}
            </v-col>
            <v-col cols="3">
              <v-icon class="mr-2">mdi-file-document-outline</v-icon>

              {{ lotto.n_documento }}
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <v-row class="mb-2">
            <v-col class="text-subtitle-2"> Dettaglio Movimenti </v-col>
          </v-row>
          <template v-if="!movimenti">
            <v-row justify="center"> Nessun movimento di vendita</v-row>
          </template>
          <template v-else>
            <ClienteEl v-for="mov in movimenti" :key="mov.cliente" :mov="mov" />
          </template>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined color="purple" @click="get_pdf()" v-if="movimenti"
          ><v-icon class="mr-2">mdi-printer</v-icon>Stampa</v-btn
        >
        <v-btn text color="grey" @click="dialog_ricerca = false">Chiudi</v-btn>
      </v-card-actions>
      <v-dialog v-model="dialog_scan" width="400">
        <StreamBarcodeReader
          v-if="dialog_scan"
          ref="reader"
          @decode="(a, b, c) => onDecode(a, b, c)"
        ></StreamBarcodeReader>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import ClienteEl from "./ricerca_lotto_cliente.vue";
import moment from "moment";
import _ from "lodash";
// import axios from "axios";
export default {
  components: {
    StreamBarcodeReader,
    ClienteEl,
  },
  data() {
    return {
      dialog_ricerca: false,
      dialog_scan: false,
      code: "",
      show_ts: false,
    };
  },
  computed: {
    movimenti() {
      if (!this.$store.getters.get_movimenti_lotti) {
        return null;
      }
      let mov_group = _.groupBy(
        this.$store.getters.get_movimenti_lotti.clienti,
        "cliente"
      );
      let mov = [];
      Object.keys(mov_group).forEach((cli) =>
        mov.push({ cliente: cli, ts: mov_group[cli].map((el) => el.ts) })
      );
      return mov;
    },
    lotto() {
      return this.$store.getters.get_movimenti_lotti.lotto;
    },
    fornitore() {
      return this.$store.getters.get_fornitore_by_id(this.lotto.id_fornitore);
    },
    avatar_fornitore() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/fornitore/" +
        this.fornitore.id +
        "/avatar"
      );
    },

    data_lotto() {
      return moment.unix(this.lotto.ts_documento).format("DD/MM/YYYY");
    },
    prodotto() {
      return this.$store.getters.get_prodotto_by_id(
        this.$store.getters.get_movimenti_lotti.prodotto
      );
    },
    nome_prodotto() {
      return this.prodotto.nome.split(" in ");
    },
    avatar_prodotto() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        this.prodotto.id +
        "?t=" +
        this.prodotto.avatar_md5
      );
    },
  },
  methods: {
    open_scan() {
      this.dialog_scan = true;
    },
    onDecode(a) {
      var audio = new Audio(require("@/assets/scan_ok.mp3"));
      audio.play();
      this.code = a;
      this.dialog_scan = false;
      this.cerca();
    },
    cerca() {
      this.$store.dispatch("ricerca_movimento_lotto", {
        id_lotto: this.code,
        ts_inizio: moment().subtract(1, "year").format("X"),
      });
    },
    get_pdf() {
      let url = process.env.VUE_APP_API_ROOT + "/admin/dettaglio_lotto_pdf";
      let auth = this.$store.getters.get_token;
      let clienti = this.movimenti.map((mov) => {
        return {
          cliente: this.$store.getters.get_cliente_by_iva(mov.cliente),
          date: mov.ts.map((ts) => moment.unix(ts).format("dddd DD MMMM YYYY")),
        };
      });
      let lotto = {...this.lotto}
      lotto.ts_documento = moment.unix(this.lotto.ts_documento).format("dddd DD MMMM YYYY")
      this.openWindowWithPost(url, {
        token: auth,
        prodotto: this.prodotto.id,
        fornitore: this.fornitore.id,
        lotto: JSON.stringify(lotto),
        clienti: JSON.stringify(clienti),
      });
    },
    openWindowWithPost(url, data) {
      var form = document.createElement("form");
      form.target = "_blank";
      form.method = "POST";
      form.action = url;
      form.style.display = "none";

      for (var key in data) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      }

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    },
  },
};
</script>
