<template>
    <span>
        <Lotti/>
        <div class="my-8"/>
        <Torbe/>
    </span>
</template>

<script>
import Lotti from "./lotti_desktop.vue";
import Torbe from "./torbe_desktop.vue";
export default {
  components: {
    Lotti,
    Torbe,
  },
};
</script>