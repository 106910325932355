<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title> Seleziona Stampa </v-card-title>
      <v-card-text>
        <v-tabs>
          <v-tab>PRODUZIONE</v-tab>
          <v-tab>COMMERCIO</v-tab>
          <v-tab-item>
            <v-row justify="center" class="mt-4">
              <v-col cols="10">
                <v-autocomplete
                  v-model="prodotto"
                  placeholder="Prodotto"
                  :items="prodotti"
                  outlined
                  item-text="nome"
                  item-value="id"
                  :readonly="editable"
                  hide-details
                >
                  <template v-slot:selection="data">
                    <v-list-item-avatar>
                      <v-img :src="avatar_img(data.item.id)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="nome_split(data.item.nome)[0]"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="nome_split(data.item.nome)[1]"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <img :src="avatar_img(data.item.id)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="nome_split(data.item.nome)[0]"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="nome_split(data.item.nome)[1]"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="10">
                <v-autocomplete
                  v-model="lotto"
                  placeholder="Lotto"
                  outlined
                  :items="lotti"
                  item-text="codice"
                  item-value="id"
                  hide-details
                >
                  <template v-slot:selection="data">
                    <v-list-item-avatar>
                      <img :src="avatar_fornit(data.item.id_fornitore)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.codice"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="nome_fornit(data.item.id_fornitore)"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <img :src="avatar_fornit(data.item.id_fornitore)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.codice"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="nome_fornit(data.item.id_fornitore)"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="10">
                <v-autocomplete
                  v-model="torba"
                  placeholder="Torba"
                  outlined
                  :items="torbe"
                  item-text="nome"
                  item-value="id"
                  hide-details
                >
                  <template v-slot:selection="data">
                    <v-list-item-avatar>
                      <img :src="avatar_fornit(data.item.id_fornitore)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.nome"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="nome_fornit(data.item.id_fornitore)"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <img :src="avatar_fornit(data.item.id_fornitore)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.nome"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="nome_fornit(data.item.id_fornitore)"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="10">
                <v-checkbox
                  label="Specifica Posizione"
                  v-model="reg_posizione"
                />
              </v-col>
              <v-col cols="10" v-if="reg_posizione">
                <v-select
                  prepend-inner-icon="mdi-greenhouse"
                  outlined
                  :items="zone_coltivazione"
                  multiple
                  chips
                  v-model="id_zone"
                  item-text="nome"
                  item-value="id"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer />
              <v-expand-x-transition>
                <v-icon class="mr-4" color="success" v-if="print_ok"
                  >mdi-check</v-icon
                >
              </v-expand-x-transition>

              <v-btn
                outlined
                color="green"
                :loading="loading"
                @click="invia_stampa()"
                :disabled="!prodotto || !torba || !lotto"
                ><v-icon class="mr-2">mdi-printer</v-icon>Stampa</v-btn
              >
              <v-btn text color="grey" @click="$emit('close')">Chiudi</v-btn>
            </v-card-actions>
          </v-tab-item>
          <v-tab-item>
            <v-row justify="" class="mt-4">
              <v-col cols="6">
                <v-autocomplete
                  v-model="lotto"
                  placeholder="Lotto"
                  outlined
                  :items="lotti"
                  item-text="codice"
                  item-value="id"
                  hide-details=""
                >
                  <template v-slot:selection="data">
                    <v-list-item-avatar>
                      <img :src="avatar_fornit(data.item.id_fornitore)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.codice"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="nome_fornit(data.item.id_fornitore)"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <img :src="avatar_fornit(data.item.id_fornitore)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.codice"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="nome_fornit(data.item.id_fornitore)"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="8">
                <v-autocomplete
                  v-model="prodotto_commercio"
                  ref="auto_prodotto"
                  placeholder="Prodotto"
                  :items="prodotti"
                  outlined
                  item-text="nome"
                  item-value="id"
                  :readonly="editable"
                >
                  <template v-slot:selection="data">
                    <v-list-item-avatar size="25">
                      <v-img :src="avatar_img(data.item.id)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="nome_split(data.item.nome)[0]"
                      ></v-list-item-title>
                      <!-- <v-list-item-subtitle
                        v-html="nome_split(data.item.nome)[1]"
                      ></v-list-item-subtitle> -->
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <img :src="avatar_img(data.item.id)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="nome_split(data.item.nome)[0]"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="nome_split(data.item.nome)[1]"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  type="number"
                  placeholder="Numero"
                  v-model="numero_commercio"
                  @keypress.enter="aggiungi_commercio()"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <div class="scroll">
              <template v-for="prodotto in lista_commercio">
                <ProdottoElement
                  :item="prodotto"
                  :key="prodotto.id"
                  @remove="rimuovi"
                />
              </template>
            </div>

            <v-card-actions class="">
              <v-spacer />
              <v-expand-x-transition>
                <v-icon class="mr-4" color="success" v-if="print_ok"
                  >mdi-check</v-icon
                >
              </v-expand-x-transition>

              <v-btn
                outlined
                color="green"
                :loading="loading"
                @click="invia_stampa_commercio()"
                :disabled="!lista_commercio.length || !lotto"
                ><v-icon class="mr-2">mdi-printer</v-icon>Stampa</v-btn
              >
              <v-btn text color="grey" @click="$emit('close')">Chiudi</v-btn>
            </v-card-actions>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ProdottoElement from "./stampa_varieta_commercio.vue";
export default {
  components: {
    ProdottoElement,
  },
  props: {
    open: Boolean,
    prodotto_from: String,
    fornitore_from: String,
  },
  data() {
    return {
      dialog: false,
      prodotto: this.prodotto_from,
      lotto: null,
      torba: null,
      loading: false,
      print_ok: false,
      prodotto_commercio: null,
      numero_commercio: 0,
      lista_commercio: [],
      reg_posizione: false,
      id_zone: [],
    };
  },
  watch: {
    open: function () {
      this.dialog = this.open;
      if (!this.open) {
        this.$emit("close");
      }
    },
  },

  computed: {
    editable() {
      return this.prodotto_from != null;
    },
    prodotti() {
      return this.$store.getters.get_lista_prodotti_raw;
    },
    zone_coltivazione() {
      return this.$store.getters.get_zone_coltivazione;
    },
    lotti() {
      let lotti_raw = this.$store.getters.get_lotti;
      if (!this.fornitore_from) {
        return lotti_raw.sort((a, b) => b.ts_documento - a.ts_documento);
      } else {
        return lotti_raw
          .filter((lotto) => lotto.id_fornitore == this.fornitore_from)
          .sort((a, b) => b.ts_documento - a.ts_documento);
      }
    },
    torbe() {
      return this.$store.getters.get_torbe;
    },
  },
  methods: {
    avatar_img(id) {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + id;
    },
    nome_split(nome) {
      return nome.split(" in ");
    },
    avatar_fornit(id) {
      return process.env.VUE_APP_API_ROOT + "/fornitore/" + id + "/avatar";
    },
    nome_fornit(id) {
      return this.$store.getters.get_fornitore_by_id(id).nome;
    },
    aggiungi_commercio() {
      let index = this.lista_commercio.findIndex(
        (el) => el.id == this.prodotto_commercio
      );
      if (index >= 0) {
        this.lista_commercio[index].qta += parseInt(this.numero_commercio);
      } else {
        this.lista_commercio.unshift({
          id: this.prodotto_commercio,
          qta: parseInt(this.numero_commercio),
        });
      }

      this.numero_commercio = 0;
      this.prodotto_commercio = null;
      this.$refs.auto_prodotto.focus();
    },
    rimuovi(id) {
      let index = this.lista_commercio.findIndex((prod) => prod.id == id);
      this.lista_commercio.splice(index, 1);
    },
    invia_stampa() {
      this.loading = true;
      this.$store
        .dispatch("imposta_stampa", {
          id_prodotto: this.prodotto,
          id_lotto: this.lotto,
          id_torba: this.torba,
          reg_posizione: this.reg_posizione,
          id_zone: this.id_zone,
        })
        .then(() => {
          this.loading = false;
          this.print_ok = true;
          setTimeout(() => {
            this.print_ok = false;
          }, 3000);
        });
    },
    invia_stampa_commercio() {
      this.loading = true;
      this.$store
        .dispatch("imposta_stampa_commercio", {
          id_lotto: this.lotto,
          lista_prodotti: this.lista_commercio,
        })
        .then(() => {
          this.loading = false;
          this.print_ok = true;
          setTimeout(() => {
            this.print_ok = false;
          }, 3000);
        });
    },
  },
};
</script>

<style scoped>
.scroll {
  height: 300px;
  overflow-y: scroll;
}
</style>