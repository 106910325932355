<template>
  <div>
    <v-row no-gutters class="my-2" align="center">
      <v-col cols="4">
        <v-icon class="mr-2 text-truncate">mdi-account</v-icon>
        <b>{{ cliente.nome }}</b>
      </v-col>
      <v-col cols="3">
        {{ cliente.indirizzo }}
      </v-col>
      <v-col cols="3">
        {{ cliente.città }}
      </v-col>
      <v-col cols="2">
        {{ mov.ts.length }}
        <v-icon @click="show_ts = !show_ts" class="ml-3">{{
          show_ts ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
        <!-- {{ data_mov(cli.ts) }} -->
      </v-col>
      <template v-if="show_ts">
        <v-col v-for="(ts, index) in mov.ts" :key="ts" cols="11" class="ml-8">
          {{index +1}} - {{ data_mov(ts) }}
        </v-col>
      </template>
    </v-row>
    <v-divider class="mb-2"/>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    mov: Object,
  },
  data() {
    return {
      show_ts: false,
    };
  },
  computed: {
    cliente() {
      return this.$store.getters.get_cliente_by_iva(this.mov.cliente);
    },
  },
  methods: {
    data_mov(ts) {
      return moment.unix(ts).format("dddd DD MMMM YYYY");
    },
  },
};
</script>