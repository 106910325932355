<template>
  <v-dialog width="400" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn outlined color="brown" v-on="on">
        <v-icon class="mr-2">mdi-plus</v-icon>
        Nuova Torba
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Nuova Torba
      </v-card-title>
      <v-card-text>
        <v-row justify="center" class="mt-4">
          <v-form v-model="valid" ref="form">
            <v-col cols="12">
              <v-autocomplete
                outlined
                prepend-inner-icon="mdi-account"
                label="Fornitore"
                :items="fornitori"
                item-text="nome"
                item-value="id"
                v-model="id_fornitore"
                :rules="rule"
              >
                <template v-slot:item="data">
                  <!-- <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template> -->
                  <template>
                    <v-list-item-avatar>
                      <v-img :src="avatar_fornitore(data.item.id)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.nome"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                prepend-inner-icon="mdi-barcode"
                label="Codice Torba"
                v-model="cod_torba"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                prepend-inner-icon="mdi-file-outline"
                label="Nome Torba"
                v-model="nome_torba"
                :rules="rule"
              ></v-text-field>
            </v-col>
          </v-form>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="dialog = false">Annulla</v-btn>
        <v-btn outlined color="green" :loading="loading" @click="invia_torba()"
          ><v-icon class="mr-2">mdi-send</v-icon> Invia</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      rule: [(v) => !!v || "Non può essere vuoto"],
      id_fornitore: null,
      cod_torba: "",
      nome_torba: "",
      loading: false,
    };
  },
  computed: {
    fornitori() {
      return this.$store.getters.get_fornitori;
    },
  },
  methods: {
    avatar_fornitore(id_fornitore) {
      return (
        process.env.VUE_APP_API_ROOT + "/fornitore/" + id_fornitore + "/avatar"
      );
    },
    invia_torba() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("invia_torba", {
          id_fornitore: this.id_fornitore,
          codice: this.cod_torba,
          nome: this.nome_torba,
        })
        .then(() => {
          this.id_fornitore = null;
          this.cod_torba = null;
          this.nome_torba = null;
          this.dialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>