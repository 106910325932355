<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-row
        align="center"
        class="py-2"
        no-gutters
        :class="hover ? 'green lighten-5' : ''"
      >
        <v-col cols="3">
          <v-avatar class="mx-2" size="30"
            ><v-img :src="avatar_fornitore"></v-img
          ></v-avatar>
          {{ fornitore.nome }}
        </v-col>
        <v-col cols="1">{{ torba.cod }}</v-col>
        <v-col cols="2">{{ torba.nome }}</v-col>

        <v-col cols="1">
          <v-dialog v-model="dialog_elimina" width="400">
            <template v-slot:activator="{ on }">
              <v-btn icon small color="red" v-on="on" v-show="hover">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="red--text"> Elimina Torba </v-card-title>
              <v-card-text> Sei Sicuro? </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  outlined
                  color="red"
                  @click="elimina_torba()"
                  :loading="loading_elimina"
                  >Elimina</v-btn
                >
                <v-btn text color="grey" @click="dialog_elimina = false"
                  >annulla</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-hover>
    <v-divider />
  </div>
</template>
<script>
export default {
  props: {
    torba: Object,
  },
  data() {
    return {
      dialog_elimina: false,
      loading_elimina: false,
    };
  },
  computed: {
    fornitore() {
      return this.$store.getters.get_fornitore_by_id(this.torba.id_fornitore);
    },
    avatar_fornitore() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/fornitore/" +
        this.fornitore.id +
        "/avatar" +
        "?t=" +
        this.fornitore.logo_md5
      );
    },
  },
  methods: {
    elimina_torba() {
      this.loading_elimina = true;
      this.$store.dispatch("delete_torba", this.torba.id).finally(() => {
        this.loading_elimina = false;
      });
    },
  },
};
</script>